import {createApp} from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import router from "./router";
import 'element-plus/dist/index.css'

const app = createApp(App)
app.use(ElementPlus)
app.use(router)

// 定义全局变量
app.config.globalProperties.$sharedObject = {
    aboutUsInfo: null,
};

app.mount('#app')
