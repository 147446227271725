<template>
  <div v-if="details" class="parent">
    <div style="display:flex;width: 70%;justify-content: center;">
      <div style="display:flex;flex-flow: column;align-items: center;width: 50%">
        <el-carousel height="320px" style="width: 320px;" :loop="false"
                     arrow="always" indicator-position="none"
                     :autoplay="false" @change="showImage" ref="banner">
          <el-carousel-item v-for="(item,index) in bannerImageList" :key="index">
            <video v-if="isVideoFile(item.path)" controls class="mainImage"
                   :src="item.path"></video>
            <el-image v-else :fit="'contain'" :src="item.path" class="mainImage"
                      :preview-teleported="true" :preview-src-list="[item.path]"/>
          </el-carousel-item>
        </el-carousel>
        <div style="margin: 12px 0">Roll over image to zoom in</div>
        <div style="display: flex;gap: 12px">
          <div v-for="(item,index) in bannerImageList" :key="index"
               @click="showImage(index,false)"
               :style="imageIndex===index?'border-radius: 2px;border: 2px solid #CB1F20;':''">
            <video v-if="isVideoFile(item.path)" class="smallImage" :src="item.path"></video>
            <el-image v-else :fit="'fill'" :src="item.path" class="smallImage"></el-image>
          </div>
        </div>
      </div>
      <div style="display: flex;flex-flow: column;gap: 6px;width: 40%">
        <div class="name">{{ details.name }}</div>
        <div class="name">{{ details.topics }}</div>
        <div style="margin-top: 3px">Color : <span style="color:#333333;">{{ colorList[colorIndex].name }}</span></div>
        <div style="display: flex;gap: 24px">
          <el-image v-for="(item,index) in colorList" :key="index" @click="chooseColor(index)"
                    :style="colorIndex===index?'border-radius: 2px;border: 2px solid #CB1F20;':''"
                    :fit="'fill'" :src="item.smallUrl" class="colorImage">
          </el-image>
        </div>
        <div style="margin-top: 3px">Main Specification:</div>
        <div v-for="(item,index) in guiGeList" :key="index"
             style="display: flex;color: #333333;font-size: 13px;align-items: center;gap: 12px">
          <span style="width: 40%;font-weight: bold;overflow-wrap: break-word;">{{ item.key }}</span>
          <span style="width: 60%;overflow-wrap: break-word;">{{ item.value }}</span>
        </div>
        <div style="background: #DDDDDD;height: 1px;width: 100%;"></div>
        <div>About this item:</div>
        <div style="color: #333333;white-space: pre-wrap;">{{ details.text }}</div>
      </div>
    </div>
    <div style="margin-top: 24px;">
      <el-image v-for="(item,index) in imageList" :fit="'fill'" :key="index" :src="item.path" class="detailsImage"/>
    </div>
  </div>
</template>

<script>

import axios from "axios";

export default {
  name: 'productDetailPage',
  components: {},
  data() {
    return {
      details: null,
      bannerImageList: [],
      imageList: [],
      guiGeList: [],
      gongNengList: [],
      colorList: [],
      imageIndex: 0,
      colorIndex: -1,
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    isVideoFile(url) {
      let videoExtensions = ['.mp4', '.mov', '.avi', '.mkv'];
      const pathname = new URL(url).pathname;
      const lastDotPosition = pathname.lastIndexOf('.');
      if (lastDotPosition === -1) return false;
      return videoExtensions.includes(pathname.substring(lastDotPosition));
    },
    showImage(index, isAuto) {
      if (index !== this.imageIndex) {
        this.imageIndex = index
        if (!isAuto) {
          this.$refs.banner.setActiveItem(index)
        }
      }
    },
    chooseColor(index) {
      if (index !== this.colorIndex) {
        this.colorIndex = index
        let temp = []
        let color = this.colorList[index]
        temp.push({
          path: color.mainUrl
        })
        temp.push(...JSON.parse(this.details.file_ids ?? "[]"))
        this.bannerImageList = temp
        this.showImage(0, false)
        window.scrollTo(0, 0);
      }
    },
    initData() {
      let id = this.$route.query.productId
      if (!id || isNaN(Number(id))) {
        alert("Invalid Product ID");
        return
      }
      axios.get('v1/product/get', {
        params: {
          id: id
        }
      }).then((res) => {
        if (res.data) {
          this.details = res.data;
          this.guiGeList = (res.data.tags ?? "").split("|||").map(item => {
            let temp = item.split(":")
            return {
              key: temp.length > 0 ? temp[0] : '',
              value: temp.length > 1 ? temp[1] : '',
            }
          })
          this.colorList = JSON.parse(res.data.specification).map(item => {
            item.mainUrl = item.image.path
            item.smallUrl = item.imageIds[0].path
            return item
          })
          this.chooseColor(0)
        } else {
          throw new Error('No data found');
        }
      }).catch(() => {
        alert('No data found');
        this.$router.back();
      });
    },
  }
}
</script>

<style lang="less" scoped>

.parent {
  padding-top: 24px;
  display: flex;
  flex-flow: column;
  align-items: center;
  background: #F8F8F8;
  color: #999999;
  font-size: 14px;

  .name {
    font-size: 16px;
    color: #333333;
    font-weight: bold;
  }
}

.mainImage {
  background: white;
  width: 320px;
  height: 320px;
  border-radius: 6px;
}

.smallImage {
  width: 48px;
  display: block;
  height: 48px;
}

.colorImage {
  width: 36px;
  height: 36px;
}

.detailsImage {
  width: 100%;
  display: block;
}

</style>
