import {createRouter, createWebHistory} from 'vue-router';
import Home from '../views/home.vue';
import Product from '../views/product.vue';
import ProductDetail from '../views/product_detail.vue';
import AboutUs from '../views/aboutus.vue';

const routes = [
    {
        path: '/',
        redirect: '/home',
        // meta: {
        //     title: '智慧声光'
        // },
        children: [
            {
                path: '/home',
                component: Home,
            },
            {
                path: '/product',
                component: Product,
            },
            {
                path: '/aboutUs',
                component: AboutUs,
            },
            {
                path: '/productDetail',
                component: ProductDetail,
            }
        ]
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    // document.title = to.meta.title ?? "羲和声光";
    next();
});

export default router;
