<template>
  <div style="background: #F8F8F8">
    <el-carousel height="700px" motion-blur>
      <el-carousel-item v-for="(item,index) in bannerImageList" :key="index">
        <video v-if="isVideoFile(item.path)" controls
               style="height:400px;width: 100%" :src="item.path">
        </video>
        <img v-else style="object-fit: fill;width: 100%;height: 100%" :src="item.path" alt="">
      </el-carousel-item>
    </el-carousel>
    <div v-if="hotStyleList && hotStyleList[0]" style="padding: 24px;margin-top: 24px">
      <img class="title_image" src="../assets/images/hot_style.png" alt="">
      <div class="image_line"></div>
    </div>
    <div class="hot_style">
      <div class="item1" v-if="hotStyleList && hotStyleList[0]"
           @click="showProduct(hotStyleList[0])">
        <img class="image" :src="hotStyleList[0].hotimg" alt="">
        <div class="item_content">{{ hotStyleList[0].name }}</div>
      </div>
      <div class="item2" v-if="hotStyleList && hotStyleList[1]"
           @click="showProduct(hotStyleList[1])">
        <img class="image" :src="hotStyleList[1].hotimg" alt="">
        <div class="item_content">{{ hotStyleList[1].name }}</div>
      </div>
    </div>
    <div class="hot_style">
      <div class="item2" v-if="hotStyleList && hotStyleList[2]"
           @click="showProduct(hotStyleList[2])">
        <img class="image" :src="hotStyleList[2].hotimg" alt="">
        <div class="item_content">{{ hotStyleList[2].name }}</div>
      </div>
      <div class="item2" v-if="hotStyleList && hotStyleList[3]"
           @click="showProduct(hotStyleList[3])">
        <img class="image" :src="hotStyleList[3].hotimg" alt="">
        <div class="item_content">{{ hotStyleList[3].name }}</div>
      </div>
      <div class="item2" v-if="hotStyleList && hotStyleList[4]"
           @click="showProduct(hotStyleList[4])">
        <img class="image" :src="hotStyleList[4].hotimg" alt="">
        <div class="item_content">{{ hotStyleList[4].name }}</div>
      </div>
    </div>
    <div style="padding: 24px;margin-top: 24px"
         v-if="flagShipProductList && flagShipProductList.length > 0">
      <img class="title_image" src="../assets/images/flagship_product.png" alt="">
      <div class="image_line"></div>
    </div>
    <div class="hot_style">
      <div class="item4" v-if="flagShipProductList && flagShipProductList[0]"
           @click="showProduct(flagShipProductList[0])">
        <img class="image" :src="flagShipProductList[0].flagimg" alt="">
      </div>
      <div style="display: flex;flex-flow: column">
        <div class="item5" v-if="flagShipProductList && flagShipProductList[1]"
             @click="showProduct(flagShipProductList[1])">
          <img class="image" :src="flagShipProductList[1].flagimg" alt="">
        </div>
        <div class="item6" v-if="flagShipProductList && flagShipProductList[2]"
             @click="showProduct(flagShipProductList[2])">
          <img class="image" :src="flagShipProductList[2].flagimg" alt="">
        </div>
      </div>
    </div>
    <div class="hot_style">
      <div style="display: flex;flex-flow: column">
        <div class="item7" v-if="flagShipProductList && flagShipProductList[3]"
             @click="showProduct(flagShipProductList[3])">
          <img class="image" :src="flagShipProductList[3].flagimg" alt="">
        </div>
        <div class="item7" v-if="flagShipProductList && flagShipProductList[4]"
             @click="showProduct(flagShipProductList[4])">
          <img class="image" :src="flagShipProductList[4].flagimg" alt="">
        </div>
      </div>
      <div class="item8" v-if="flagShipProductList && flagShipProductList[5]"
           @click="showProduct(flagShipProductList[5])">
        <img class="image" :src="flagShipProductList[5].flagimg" alt="">
      </div>
    </div>
    <div style="height: 24px"></div>
    <div style="position: relative">
      <div style="display: flex;padding: 48px 0;background: white;height: 720px">
        <div style="flex: 1;display: flex;">
          <img style="flex: 0 0 auto;width: 156px;height: 100%" src="../assets/images/about_us3.png" alt="">
          <div style="display: flex;flex-flow: column;flex: 1;margin: 24px;font-size: 24px;">
            <img style="width: 256px" src="../assets/images/about_us.png" alt="">
            <div style="font-size: 40px;margin: 18px 0">What's we do?</div>
            <div style="flex: 1; white-space: pre-wrap;text-align: justify;">{{ aboutUs }}</div>
          </div>
        </div>
        <div style="flex: 1;display: flex;background: #007DE7">
          <img src="../assets/images/about_us4.png" alt="" style="width: 100%;height :100%;object-fit: fill">
        </div>
      </div>
      <div style="display: flex;position: absolute;bottom: 96px;left: 50%;transform: translateX(-50%)">
        <div class="about_text1">
          <div style="display: flex;align-items: center;height: 56px;">
            <span style="width: 350px">9 years of development history</span>
          </div>
          <div style="font-size: 48px;">9</div>
        </div>
        <div class="about_text2">
          <div style="display: flex;align-items: center;height: 56px;">
            <span style="width: 350px">64﹢Software copyright</span>
          </div>
          <div style="font-size: 48px;color: #CB1F20;display: flex">
            <span>64</span>
            <span style="font-size: 32px">+</span>
          </div>
        </div>
        <div class="about_text2">
          <div style="display: flex;align-items: center;height: 56px;">
            <span style="width: 350px"> 14﹢Authoritative testing<br>by the Ministry of Public Security</span>
          </div>
          <div style="font-size: 48px;color: #CB1F20;display: flex">
            <span>14</span>
            <span style="font-size: 32px">+</span>
          </div>
        </div>
        <div class="about_text2">
          <div style="display: flex;align-items: center;height: 56px;">
            <span style="width: 350px">3﹢Management system certification</span>
          </div>
          <div style="font-size: 48px;color: #CB1F20;display: flex">
            <span>3</span>
            <span style="font-size: 32px">+</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from "axios";

export default {
  name: 'homePage',
  data() {
    return {
      bannerImageList: [],
      hotStyleList: [],
      flagShipProductList: [],
      aboutUs: null,
      cover: {},
    }
  },
  mounted() {
    this.initBanner()
    this.initProduct()
    this.initAboutUs()
  },
  methods: {
    initAboutUs() {
      axios.get('v1/about/get').then((res) => {
        if (res.data.code === '1') {
          this.aboutUs = res.data.data.description
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(res => {
        console.log(res)
      });
    },
    isVideoFile(url) {
      let videoExtensions = ['.mp4', '.mov', '.avi', '.mkv'];
      const pathname = new URL(url).pathname;
      const lastDotPosition = pathname.lastIndexOf('.');
      if (lastDotPosition === -1) return false;
      return videoExtensions.includes(pathname.substring(lastDotPosition));
    },
    initBanner() {
      axios.get('v1/machine/getByKind', {
        params: {
          kind: '1',
        }
      }).then((res) => {
        this.bannerImageList = res.data.data.filter(item => {
          return item.shows
        }).sort((a, b) => {
          if (a.ranking && !b.ranking) return -1
          if (!a.ranking && b.ranking) return 1
          let aSort = Number(a.ranking)
          let bSort = Number(b.ranking)
          if (aSort > bSort) {
            return 1
          } else if (aSort < bSort) {
            return -1
          }
          return 0
        })
      }).catch(res => {
        console.log(res)
      });
    },
    initProduct() {
      axios.get('v1/product/getProducts', {
        params: {
          count: 9999
        }
      }).then((res) => {
        this.hotStyleList = res.data.items.filter(item => {
          if (item.hotimg) {
            item.hotimg = JSON.parse(item.hotimg).path
          }
          return item.hot === '1'
        }).sort((a, b) => {
          if (a.ranking && !b.ranking) return -1
          if (!a.ranking && b.ranking) return 1
          let aSort = Number(a.ranking)
          let bSort = Number(b.ranking)
          if (aSort > bSort) {
            return 1
          } else if (aSort < bSort) {
            return -1
          }
          return 0
        })
        console.log(this.hotStyleList)
        this.flagShipProductList = res.data.items.filter(item => {
          if (item.flagimg) {
            item.flagimg = JSON.parse(item.flagimg).path
          }
          return item.flag && item.flag !== '' && item.flag !== '0'
        }).sort((a, b) => {
          let aSort = Number(a.flag)
          let bSort = Number(b.flag)
          if (aSort > bSort) {
            return 1
          } else if (aSort < bSort) {
            return -1
          }
          return 0
        })
        console.log(this.flagShipProductList)
      }).catch(res => {
        console.log(res)
      });
    },
    showProduct(product) {
      this.$router.push({path: "/productDetail", query: {productId: product.id}})
    },
  }
}
</script>

<style lang="less" scoped>

.about_text1 {
  display: flex;
  font-size: 18px;
  background: #CB1F20;
  justify-content: center;
  padding: 48px 12px;
  align-items: center;
  color: white;
  flex: 1;
  flex-flow: column;
  text-align: center
}

.about_text2 {
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  padding: 48px 12px;
  color: #333333;
  align-items: center;
  background: rgba(255, 255, 255, 0.9);
  flex: 1;
  flex-flow: column;
  text-align: center
}

.title_image {
  height: 54px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.image_line {
  background-color: #CB1F20;
  height: 8px;
  width: 68px;
  margin-top: 24px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.hot_style {
  box-sizing: border-box;
  width: 100%;
  justify-content: center;
  display: flex;

  .item_content {
    width: 100%;
    background: #CB1F20;
    text-align: center;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 300;
    font-size: 22px;
    line-height: 80px;
    color: #FFFFFF;
    font-style: italic;
  }

  .image {
    width: 100%;
    background: white;
    object-fit: fill;
    height: 576px;
  }

  .item1 {
    flex-grow: 2;
    margin: 6px;
    display: flex;
    flex-flow: column;
    max-width: 920px;
  }

  .item2 {
    margin: 6px;
    flex-grow: 1;
    display: flex;
    flex-flow: column;
    max-width: 454px;
  }

  .item4 {
    flex-grow: 1;
    display: flex;
    margin: 6px;
    flex-flow: column;
    max-width: 677px;

    .image {
      width: 677px;
      height: 1032px;
    }
  }

  .item5 {
    flex-grow: 1;
    display: flex;
    margin: 6px;
    flex-flow: column;
    max-width: 692px;

    .image {
      width: 692px;
      height: 394px;
    }
  }

  .item6 {
    flex-grow: 1;
    display: flex;
    margin: 6px;
    flex-flow: column;
    max-width: 692px;

    .image {
      width: 692px;
      height: 624px
    }
  }

  .item7 {
    flex-grow: 1;
    display: flex;
    margin: 6px;
    flex-flow: column;
    max-width: 436px;

    .image {
      width: 436px;
      height: 295px;
    }
  }

  .item8 {
    flex-grow: 1;
    display: flex;
    margin: 6px;
    flex-flow: column;
    max-width: 935px;

    .image {
      width: 935px;
      height: 604px;
    }
  }
}
</style>