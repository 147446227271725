<template>
  <Index/>
</template>

<script>
import Index from '@/views/index.vue'

export default {
  name: 'App',
  components: {
    Index,
  }
}
</script>
