<template>
  <div>
    <header class="head">
      <img class="logo" src="../assets/images/logo.png" alt="">
      <div style="width: 128px"></div>
      <div style="flex: 1;display: flex;justify-content: center">
        <el-button style="margin: 0 36px" link plain
                   :style="page===1?'color:#CB1F20':'color:#333333'"
                   @click="changePage(1)">Home
        </el-button>
        <el-button style="margin: 0 36px" link plain
                   :style="page===2?'color:#CB1F20':'color:#333333'"
                   @click="changePage(2)">Product Center
        </el-button>
        <el-button style="margin: 0 36px" link plain
                   :style="page===3?'color:#CB1F20':'color:#333333'"
                   @click="changePage(3)">About Us
        </el-button>
      </div>
    </header>
    <main>
      <router-view></router-view>
    </main>
    <footer class="foot">
      <div style="display: flex;">
        <div class="left">
          <div class="title">CONTACT US</div>
          <div style="width: 28px;height: 8px;background: white;margin-top: 6px"></div>
          <div v-for="(item,index) in contactInfo" :key="index">
            <div class="subtitle">{{ item.key }}</div>
            <div class="content">{{ item.value }}</div>
          </div>
          <div style="margin-top: 48px;display: flex;align-items: center">
            <img class="call_icon" src="../assets/images/phone.png" alt="">
            <span class="phone">+86 0791-8283-2897</span>
          </div>
        </div>
        <div class="right">
          <div class="title">LEAVE A MESSAGE</div>
          <div class="subtitle">YOUR NAME</div>
          <el-input v-model="form.name" class="input"></el-input>
          <div class="subtitle">YOUR EMAIL</div>
          <el-input v-model="form.email" class="input"></el-input>
          <div class="subtitle">YOUR PHONE</div>
          <el-input v-model="form.phone" class="input"></el-input>
          <div class="subtitle">YOUR MESSAGE</div>
          <el-input v-model="form.message" type="textarea" rows="3" style="width: 85%" class="input"></el-input>
          <el-button @click="submitMessage" class="button">SUBMIT</el-button>
        </div>
      </div>
      <div class="bottom"></div>
      <div class="bottom">Copyright © 2024 - 2034 vkeline. All Rights Reserved 赣ICP备16011103号-1</div>
    </footer>
  </div>
</template>

<script>

import axios from "axios";

export default {
  name: 'indexPage',
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        this.onPageChange(to);
      }
    }
  },
  data() {
    return {
      form: {
        name: '',
        email: '',
        phone: '',
        message: '',
      },
      page: 1,
      contactInfo: [],
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    onPageChange(to) {
      if (to.path === '/home') {
        this.page = 1
      } else if (to.path === '/product') {
        this.page = 2
      } else if (to.path === '/aboutUs') {
        this.page = 3
      }
    },
    initData() {
      axios.get('v1/about/get').then((res) => {
        if (res.data.code === '1') {
          if (res.data.data.process && res.data.data.process !== '') {
            this.$sharedObject.aboutUsInfo = res.data.data
            this.contactInfo = res.data.data.process.split("|||").map(item => {
              let temp = item.split(":")
              return {
                key: temp.length > 0 ? temp[0] : '',
                value: temp.length > 1 ? temp[1] : '',
              }
            })
          }
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(res => {
        console.log(res)
      });
    },
    submitMessage() {
      axios.post('v1/replies/add', null, {
        params: {
          content: this.form.message,
          userName: this.form.name,
          email: this.form.email,
          phone: this.form.phone,
        }
      }).then(() => {
        this.$message.success("Submit Success")
      }).catch(res => {
        this.$message.error(res.message)
      });
    },
    changePage(page) {
      if (page === 1) {
        this.$router.replace("/home")
      } else if (page === 2) {
        this.$router.replace("/product")
      } else if (page === 3) {
        this.$router.replace("/aboutUs")
      }
    }
  }
}
</script>

<style lang="less" scoped>
.head {
  display: flex;
  padding: 16px;
  align-items: center;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.8);

  :deep(.el-button) {
    font-weight: 400;
    font-size: 20px;
  }

  .logo {
    box-sizing: border-box;
    padding: 3px;
    height: 36px;
  }
}

.foot {
  width: 100%;
  padding: 24px 36px 12px;
  box-sizing: border-box;
  background: #2A2A2A;

  .title {
    font-weight: 800;
    font-size: 32px;
    color: #FFFFFF;
    font-style: italic;
  }

  .left {
    box-sizing: border-box;
    max-width: 50%;
    flex-grow: 1;

    .subtitle {
      font-weight: 300;
      margin-top: 24px;
      font-size: 16px;
      color: #FFFFFF;
      font-style: italic;
    }

    .content {
      margin-top: 3px;
      font-weight: 400;
      font-size: 20px;
      color: #FFFFFF;
      font-style: italic;
    }

    .call_icon {
      margin-right: 6px;
      margin-top: 4px;
      width: 24px;
      height: 24px;
    }

    .phone {
      font-weight: 400;
      font-size: 32px;
      color: #FFFFFF;
    }
  }

  .right {
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    max-width: 50%;
    flex-grow: 1;

    .subtitle {
      font-weight: 300;
      margin-top: 18px;
      font-size: 16px;
      color: #FFFFFF;
      font-style: italic;
    }

    .input {
      margin-top: 6px;
      width: 50%;
      color: white;
    }

    :deep(.el-input__wrapper) {
      background: transparent;
      height: 36px;
      border-radius: 6px;
    }

    :deep(.el-textarea__inner) {
      background: transparent;
      border-radius: 6px;
    }

    :deep(.el-input__inner) {
      color: white;
    }

    :deep(.el-textarea__inner) {
      color: white;
    }

    .button {
      font-weight: 400;
      width: 164px;
      font-size: 20px;
      margin-top: 24px;
      padding: 20px 24px;
      outline-width: 0;
      color: white;
      background: #007DE7;
    }
  }

  .bottom {
    margin-top: 36px;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    color: #6F6F6F;
  }
}

</style>
