<template>
  <div style="background: #F8F8F8;padding-bottom: 56px">
    <el-carousel height="700px" indicator-position="none">
      <el-carousel-item v-for="(item,index) in bannerImageList" :key="index">
        <img style="object-fit: fill;width: 100%;height: 100%" :src="item.url" alt="">
      </el-carousel-item>
    </el-carousel>
    <el-input
        v-model="searchKey"
        class="search_input"
        size="large"
        placeholder="Search">
      <template #append>
        <el-icon @click="initData" style="width: 60px">
          <Search style="color: #CB1F20;;font-size: 20px"/>
        </el-icon>
      </template>
    </el-input>
    <div style="display: flex;justify-content: center">
      <div style="display: flex;flex-flow: column">
        <div v-for="(item,index) in productList" :key="index" class="product">
          <div v-for="product in item" :key="product.id" class="item">
            <video v-if="isVideoFile(product.headline)" class="image" :src="product.headline"></video>
            <el-image v-else class="image" :src="product.headline" fit="fill"></el-image>
            <div class="title">{{ product.name }}</div>
            <div class="subtitle">{{ product.topics ?? ' ' }}</div>
            <el-button class="more_button" @click="productDetails(product)">
              Learn More
              <Right class="icon"/>
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {Right, Search} from '@element-plus/icons-vue'
import axios from "axios";

export default {
  name: 'productPage',
  components: {
    Search,
    Right,
  },
  data() {
    return {
      searchKey: '',
      bannerImageList: [
        {
          url: require('@/assets/images/bg_product1.png')
        },
        {
          url: require('@/assets/images/bg_product2.png')
        },
        {
          url: require('@/assets/images/bg_product3.png')
        }
      ],
      productList: [],
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    isVideoFile(url) {
      let videoExtensions = ['.mp4', '.mov', '.avi', '.mkv'];
      const pathname = new URL(url).pathname;
      const lastDotPosition = pathname.lastIndexOf('.');
      if (lastDotPosition === -1) return false;
      return videoExtensions.includes(pathname.substring(lastDotPosition));
    },
    initData() {
      axios.get('v1/product/getProducts', {
        params: {
          count: 9999
        }
      }).then((res) => {
        let temp = res.data.items.filter(item => {
          return item.shows && item.name.includes(this.searchKey)
        }).sort((a, b) => {
          if (a.ranking && !b.ranking) return -1
          if (!a.ranking && b.ranking) return 1
          let aSort = Number(a.ranking)
          let bSort = Number(b.ranking)
          if (aSort > bSort) {
            return 1
          } else if (aSort < bSort) {
            return -1
          }
          return 0
        })
        let productList = []
        for (let i = 0; i < temp.length; i++) {
          if (i % 3 === 0) {
            productList.push([])
          }
          productList[productList.length - 1].push(temp[i])
        }
        this.productList = productList
      }).catch(res => {
        console.log(res)
      });
    },
    productDetails(product) {
      this.$router.push({path: "/productDetail", query: {productId: product.id}})
    },
  }
}
</script>

<style lang="less" scoped>

.search_input {
  margin: 36px 24px 24px 24px;
  font-size: 20px;
  width: 600px;
  height: 60px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.product {
  display: flex;
  flex-flow: row;
  gap: 24px;

  .item {
    background: white;
    text-align: center;
    padding: 6px;
    box-sizing: border-box;
    margin: 12px 0;

    .image {
      background: #F8F8F8;
      display: block;
      width: 418px;
      height: 418px;
    }

    .title {
      color: #333333;
      max-width: 418px;
      padding: 12px;
      box-sizing: border-box;
      font-size: 24px;
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .subtitle {
      max-width: 418px;
      color: #666666;
      box-sizing: border-box;
      font-size: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .more_button {
      font-size: 20px;
      margin-top: 24px;
      margin-bottom: 18px;
      color: white;
      background: #CB1F20;
      border-color: transparent;
      border-radius: 2px;
      padding: 20px 24px;

      .icon {
        margin-left: 3px;
        width: 20px;
        height: 20px;
      }
    }
  }
}

:deep(.el-carousel__arrow) {
  display: none;
}
</style>
